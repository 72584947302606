<template>
	<div>
<!--		<span style="font-size: 10px;">搜索：</span>-->
<!--		<el-select style="width: 200px" v-model="currentSubEquipnameId" placeholder="请选择"-->
<!--			@change="selectCurrentpoint()">-->
<!--			<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">-->
<!--			</el-option>-->
<!--		</el-select>-->

		<el-card class="box-card" v-for="(item,index) in mpArray">
			<span v-if="item.name==''">{{item.name}}无数据</span>
			<span v-if="item.type=='string' || item.type=='boolean'">{{item.name}}的类型{{item.type}}不支持曲线展示</span>
			<mpGraph2  :beforeData='item.before' :afterData='item.after' v-if="item.type!=='string' && item.type!=='boolean'"
			:xTime='item.time' :title='item.name'></mpGraph2>
		</el-card>
<!--需要当前设备id，附属设备id，hbase表名-->



	</div>
</template>

<script>
	import {
		pinyin
	} from 'pinyin-pro';
	// import mpGraph1 from '../../../../components/mpGraph/mpGraph1.vue'
	import mpGraph2 from '/src/views/modules/dataManage/dataList/cengji/mpGraph2.vue'
	// import mpGraph3 from '../../../../components/mpGraph/mpGraph3.vue'
	// import mpGraph4 from '../../../../components/mpGraph/mpGraph4.vue'
	import {
		closeSync
	} from 'fs';
	export default {
		name: "dataDetail_graph",
		data() {
			return {
				start: 1,
				showReject: false,
				before: [1, 2, 3, 4],
				page: 1, //当前第几页
				size: 10, //当前每页页数
				currentSubEquipnameId: '',
				currentEquipId: 0,
				// 父组件向子组件传递的值
				// mpArray: [
        //   {
        //     before: [1,2,3,4],
        //     after:[5,6,7,8],
        //     name:'电流',
        //     time:[1,2,3,4]
        //   }
        // ],
        mpArray:[],
				// hbasetablename: '',
				options: [],
				mpName: [],
        mpType: [],
				// 根据附属设备id获取到的数据项个数
				mpHeaderLength: [],

        timer: null

			}
		},
    props:['tableHeaderTest','hbaseTableName'],
    components: {
      // mpGraph1,
      mpGraph2,
      // mpGraph3,
      // mpGraph4
    },
    mounted() {
      // this.currentEquipId = this.$route.query.equipmentId
      // this.currentEquipId = 7
      // this.currentEquipId = window.sessionStorage.getItem("dataList_currentEquipId");
      // 获取附属设备选项

      this.getSubEquipOption();
      // console.log("here1")
      // this.initData();
      // console.log("here2")
      // this.timer = setInterval(() => {
      // 	this.initData1();
      // }, 5000)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
      console.log("graph kill")
    },

		methods: {

      // 改变当前附属设备选项
      // selectCurrentpoint() {
      //     if (this.timer !== null) {
      //       clearInterval(this.timer)
      //       this.timer = null
      //     }
      //   for (let i in this.options) {
      //     if (this.options[i].id == this.currentSubEquipnameId) {
      //       this.subEuipname = this.options[i].name;
      //       this.hbasetablename = this.options[i].hbasetablename;
      //       // console.log("this.hbasetablename"+this.hbasetablename);
      //       break;
      //     }
      //   }
      //   this.initData();
      //   // this.getHbaseData1()
      // },
      // 根据传来的设备currentEquipId找到对应的附属设备选项
      getSubEquipOption() {
        this.initData();
        // this.getRequest('/subEquipmentManage/getSubEquipmentInfoByEquipmentId/?equipmentId=' + this.currentEquipId).then(
        //     resp => {
        //       if (resp.data) {
        //         console.log(resp.data)
        //         this.options = resp.data.data;
        //         this.currentSubEquipname = this.options[0].name; //将当前子系统与id设置为下拉框第一个
        //         this.currentSubEquipnameId = this.options[0].id;
        //         // this.subEuipname = this.options[0].name;
        //         this.hbasetablename = this.options[0].hbasetablename;
        //         // this.hbasetablename = this.options[0].hbasetablename;
        //         // console.log("options");
        //         // console.log(this.options);
        //         this.initData();
        //       }
        //
        //     });

      },
      initData() {
        var mpNames = [];
        var mpTypes = [];

        for(let i=0;i<this.tableHeaderTest.length;i++){
          mpNames[i] = this.tableHeaderTest[i].name;
          mpTypes.push('float');
          // console.log('pppp',pinyinHeader[0])
        }
        //每列名字
        this.mpName = mpNames;
        this.mpType = mpTypes
        this.startGetData()

        // this.currentSubEquipnameId = parseInt(this.currentSubEquipnameId);
        // this.getRequest('/measurePointManage/getMeasurePointInfoBySubEquipmentId/?subEquipmentId=' + this
        //     .currentSubEquipnameId + '&page=' + this.page + '&size=' + this.size).then(resp => {
        //   // if (resp.data) {
        //
        //     // {id: 12, name: "电机电流i", uid: null, createdate: "2021-10-04", delete: null, remark: "电机电流I",…}
        //     // this.mpHeaderLength = resp.data.data.length;
        //     // 所有数据项名字的数组
        //     // var mpNames = [];
        //     // var mpTypes = [];
        //     //
        //     // for(let i=0;i<this.tableHeaderTest.length;i++){
        //     //   mpNames[i] = this.tableHeaderTest[i].name;
        //     //   mpTypes.push('float');
        //     //   // console.log('pppp',pinyinHeader[0])
        //     // }
        //     // this.mpName = mpNames;
        //     // this.mpType = mpTypes
        //
        //     // for (let i = 0; i < resp.data.data.length; i++) {
        //     //   //每列名字
        //     //   mpNames.push(resp.data.data[i].name);
        //     //   //float
        //     //   mpTypes.push(resp.data.data[i].type);
        //     // }
        //     // this.mpName = mpNames;
        //     // this.mpType = mpTypes
        //     // this.total = resp.data.total;
        //     // var mpLength = this.mpName.length;
        //     // var arr = [];
        //     this.startGetData()
        //
        //   // }
        // })

      },

      handleHbaseSearch() {
        let arr = [];

        for (let mpName of this.mpName) {
          console.log(mpName)
          //arr为获得的数据
          arr.push(this.getHbaseData(mpName));
        }
        console.log(arr)
        Promise.all(arr).then(resp => {
          this.showReject = true;
          var mpInfo = [];
          console.log("333")
          console.log(resp)
          //每个的数据
          console.log(resp[0].data)
          for (var i = 0; i < resp.length; i++) {
            //有多少列
            // 每个数据项有20个数据
            var o = {
              name: '',
              type: '',
              before: [],
              after: [],
              time: []
            };
            for (var j = 0; j < resp[i].data.length; j++) {
              //每列有多少个数据
              o.name = this.mpName[i];
              o.type = this.mpType[i];
              o.time.push(resp[i].data[j].date);
              for (var key in resp[i].data[j]) {
                if (key.indexOf("before") !== -1) {
                  console.log(eval("resp[i].data[j]." + key));
                  o.before.push(eval("resp[i].data[j]." + key));
                }
                if (key.indexOf("after") !== -1) {
                  o.after.push(eval("resp[i].data[j]." + key));
                }
              }
            }
            mpInfo.push(o);

          }
          console.log('mpInfo');
          console.log(mpInfo);
          this.mpArray = mpInfo;

        })
      },
      getHbaseData(mpName) {
        // 获得每一列的数据

        return this.getRequest('/hbase/getMeasurePointByname/?hbasetablename=' + this.hbaseTableName +
            '&qualifier=' +
            pinyin(mpName, {
              toneType: 'none'
            }).replace(/\s*/g, '') + '&page=' + this.page + '&size=' + this.size)

      },
      startGetData() {
        clearInterval(this.timer);
        this.getRequest(
            "/hbase/isHbaseTableExists/?hbaseTableName=" +
            this.hbaseTableName
        ).then((resp) => {
          if (resp.data.msg === "success") {
            this.timer = setInterval(() => {

              this.handleHbaseSearch()

            }, 2000)
          }
        })
      }
    }
	}
</script>

<style>
	.el-card {
		margin-top: 10px;
		margin-left: 35px;
		margin-right: 35px;
	}
</style>
